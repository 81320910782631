<template>
  <div class="home">
    <div class="home-one">
      <div class="one-left">
        <el-image :src="img_one"></el-image>
      </div>
      <div class="one-right">
        <div class="right-top">
          <span>广视慧眼</span>
          <el-divider direction="vertical"></el-divider>
          <span>做您的守护者</span>
        </div>
        <div class="right-title">智能监控在线助手</div>
        <!-- <el-divider class="line1"></el-divider>
        <el-divider class="line2"></el-divider> -->
        <div class="right-code">
          <span>扫码下载</span>
          <el-image :src="img_two"></el-image>
        </div>
      </div>
    </div>
    <div class="home-two">
      <div class="two-content">
        <div class="content-title">
          <span>广视慧眼</span>
          <el-divider class="line"></el-divider>
        </div>
        <div class="content-doc">
          家居/商铺无线视频监控系统——广视慧眼，是对远程目标和受监测点进行监控管理体系中的一个重要组成部分，是一种高科技智能型的综合系统。它可以通过摄像机或其辅助设备（镜头、云台等）直接观看被监控场地的一切情况，解决了传统的网络无法实现布线而又必须远程监控。本系统广泛应用在:家居安全，防盗，远程看家等方面。
        </div>
      </div>
    </div>
    <div class="home-three">
      <div class="three-left">
        <el-image :src="img_three"></el-image>
      </div>
      <div class="three-right">
        <div class="right-title">六大主要功能</div>
        <ul>
          <li v-for="(item, index) in feature" :key="index">{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div class="home-four">
      <div class="footer">@四川广飞视信息技术有限公司</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img_one: require("@/assets/image/img_one.png"),
      img_two: require("@/assets/image/img_two.png"),
      img_three: require("@/assets/image/img_three.png"),
      feature: [
        {
          name: "多组织、多场景设置",
        },
        {
          name: "双语音对讲",
        },
        {
          name: "多平台兼容",
        },
        {
          name: "录像回放",
        },
        {
          name: "环境适应性强",
        },
        {
          name: "移动侦测",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  background: #303136;
  .home-one {
    height: 1213px;
    background: url("~@/assets/image/one.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .one-left {
      width: 525px;
      height: 964px;
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .one-right {
      margin-left: 119px;
      .right-top {
        display: flex;
        align-items: center;
        span {
          font-size: 30px;
          font-family: Microsoft YaHei;
          color: #dedede;
        }
        span:nth-child(1) {
          font-weight: bold;
        }
        .el-divider--vertical {
          width: 5px;
          height: 27px;
          margin: 0 30px;
          background: #f6f6f6;
        }
      }
      .right-title {
        font-size: 108px;
        margin-top: 70px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #dedede;
        
      }
      .el-divider--vertical {
        height: 2px;
        background: #f6f6f6;
      }
      .line1 {
        width: 80%;
        margin-top: 53px;
      }
      .line2 {
        width: 60%;
        margin-top: 48px;
      }
      .right-code {
        width: 303px;
        height: 322px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        span {
          font-size: 30px;
          margin: 8px 0;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
        .el-image{
          width: 95%;
          height: 80%;
        }
      }
    }
  }
  .home-two {
    height: 885px;
    margin-top: 28px;
    background: url("~@/assets/image/two.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .two-content {
      width: 828px;
      height: 428px;
      background: rgba(36,37,38,0.8);
      padding: 49px 60px 0 79px;
      box-sizing: border-box;
      text-align: left;
      position: absolute;
      top: 276px;
      left: 280px;
      .content-title {
        font-size: 72px;
        font-family: MiSans;
        font-weight: bold;
        color: #ffffff;
        .line {
          width: 15%;
          height: 3px;
          border-radius: 2px;
        }
      }
      .content-doc {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-top: 40px;
        padding-bottom: 77px;
        box-sizing: border-box;
        white-space: pre-wrap;
      }
    }
  }
  .home-three {
    width: 100%;
    height:1406px;
    margin-top: 28px;
    background: url("~@/assets/image/three.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    .three-left {
      width: 494px;
      height: 1053px;
      margin-left: 426px;
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .three-right {
      align-self: flex-start;
      margin-left: 140px;
      margin-top: 379px;
      .right-title {
        font-size: 48px;
        font-family: MiSans;
        font-weight: bold;
        color: #dedede;
      }
      ul {
        li {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #dedede;
          text-align: left;
          padding:17px 0;
          box-sizing: border-box;
        }
      }
    }
  }
  .home-four {
    height: 395px;
    background: url("~@/assets/image/four.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 90px;
      background: rgba(56,56,56, 0.7);
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dedede;
      line-height: 90px;
    }
  }
}
@media screen and(max-width:800px) {
  .home{
    .home-two{
      .two-content{
        box-sizing: content-box;
        .content-doc{
          box-sizing: content-box;
        }
      }
    }
  }
}
</style>